<template>
  <index-content>
    <template #options>
      <nb-refresh @on-click="load" />
      <nb-add @on-click="onBtnAdd" />
    </template>

    <search-condition :label-width="100">
      <n-field-select v-model="domain" label-align="left" label="domain" all-option :options="domains" @on-change="load" />
    </search-condition>

    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <thead>
          <tr>
            <th class="domain">{{ $t('label.domain') }}</th>
            <th class="id">{{ $t('label.id') }}</th>
            <th class="companyId">{{ $t('label.company') }}</th>
            <th class="description">{{ $t('label.description') }}</th>
            <th class="version">{{ $t('label.version') }}</th>
            <th v-rw class="action-remove">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.domain | hyphen }}</td>
            <td>{{ row.id | hyphen }}</td>
            <td v-selection:[`company.company`]="row.domain === 'COMPANY' ? row.id : row.companyId"></td>
            <td>{{ row.description | hyphen }}</td>
            <td class="n-text-right">{{ row.version | hyphen }}</td>
            <td v-rw>
              <nb-confirm text="remove" @on-ok="doRemove(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import SystemView from '../view';
import { flatMap, keys } from 'lodash-es';
import ModalAddDebug from './ModalAddDebug';
import { debug, fetch } from '@/api/system/system-debug';

export default SystemView.extend({
  name: 'SystemDebug',
  data() {
    return {
      preload: true,
      domain: 0,
      domains: [],
    };
  },

  methods: {
    doLoad() {
      return fetch();
    },

    parse(data) {
      this.domains = keys(data).map(key => ({ name: key, value: key }));
      this.records = this.domain === 0 ? flatMap(data) : data[this.domain];
    },

    doRemove(row) {
      return debug(row.domain, { id: row.id, verbose: false });
    },

    onBtnAdd() {
      this.createModal(ModalAddDebug, { model: { domain: this.domain, domains: this.domains, id: '' }, on: this });
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  min-width: 580px;
}
</style>
