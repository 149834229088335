<template>
  <n-modal v-bind="$props" :width="500">
    <!-- prettier-ignore -->
    <Form ref="modalForm" class="n-modal-container" :label-width="130" :rules="rules" :model="model" @submit.native.prevent>
      <n-field-select v-model="model.domain" label="domain" field-name="domain" placeholder="pleaseSelectDomain" :error="error.domain" :options="model.domains" :disabled="disabled" />
      <n-field-text v-model="model.debugId" label="id" field-name="debugId" :error="error.id" :disabled="disabled" />
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { debug } from '@/api/system/system-debug';
import { shouldNotEmpty } from '@/helpers/validate';

export default NModal.extend({
  name: 'ModalAddDebug',
  components: { NModal },
  data() {
    return {
      rules: {
        domain: [shouldNotEmpty],
        debugId: [shouldNotEmpty],
      },
    };
  },

  methods: {
    doValidate() {
      return Promise.resolve({ type: 'SUCCESS' });
    },
    doSubmit() {
      return debug(this.model.domain, { id: this.model.debugId, verbose: true });
    },
  },
});
</script>
